import type { GatsbyBrowser } from "gatsby";

export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
  if (!(`IntersectionObserver` in window)) {
    require(`intersection-observer`);
    console.log(`# IntersectionObserver is polyfilled!`);
  }
};

export const onInitialClientRender: GatsbyBrowser["onInitialClientRender"] =
  () => {
    // Loading Init
  };

export const onPreRouteUpdate: GatsbyBrowser["onPreRouteUpdate"] = () => {
  const _body = document.querySelector("body");
  _body?.classList.remove("ready");
};

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({ location }) => {
  const _body = document.querySelector("body");
  const _nav_btn = document.getElementById("nav_btn");
  _body?.classList.add("ready");
  _body?.classList.remove("navopen");
  if (_nav_btn != null) {
    document.getElementById("nav_btn")?.addEventListener(
      "click",
      function () {
        _body?.classList.toggle("navopen");
      },
      false
    );
  }

  const setFillHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", vh + "px");
  };
  window.addEventListener("resize", setFillHeight);
  setFillHeight();
};
