exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cases-tsx": () => import("./../../../src/pages/cases.tsx" /* webpackChunkName: "component---src-pages-cases-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-consulting-tsx": () => import("./../../../src/pages/consulting.tsx" /* webpackChunkName: "component---src-pages-consulting-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-contents-tsx": () => import("./../../../src/pages/contents.tsx" /* webpackChunkName: "component---src-pages-contents-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-logix-index-tsx": () => import("./../../../src/pages/logix/index.tsx" /* webpackChunkName: "component---src-pages-logix-index-tsx" */),
  "component---src-pages-logix-terms-tsx": () => import("./../../../src/pages/logix/terms.tsx" /* webpackChunkName: "component---src-pages-logix-terms-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-thanks-tsx": () => import("./../../../src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */),
  "component---src-templates-archive-tsx": () => import("./../../../src/templates/archive.tsx" /* webpackChunkName: "component---src-templates-archive-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

